import { createSlice } from "@reduxjs/toolkit";

/**
 * Imports types
 */
import { AppThunk } from "../../../redux";
import { ReduxSlice } from "../../features";
import { AccountState } from "./account.types";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  User,
  Account,
  CarType,
  Product,
  WheelType,
  Timesheet,
  AccountUser,
  PaymentType,
  FilterModel,
  AvatarOption,
  AccountSetting,
  WorkOrderWorker,
  UserOrganization,
  AppointmentGroup,
  SocketEvent,
} from "../../../types";

/**
 * Imports APIs
 */
import {
  switchUser,
  searchUsers,
  searchWorkers,
  searchProducts,
  fetchAccountInformation,
  getAccountOrganizations,
} from "../../features";

/**
 * Imports utils
 */
import {
  formatCarModels,
  preloadLockedState,
  preloadDeletedState,
  buildUserAccountData,
  getAppointmentGroups,
  preloadAvatarConfigState,
} from "./account.utils";
import { uniqBy } from "lodash";
import { toHashMap } from "../../utils";

/**
 * Defines the account initial state
 */
const initialState: AccountState = {
  storedAvatarConfig: preloadAvatarConfigState(),
  locked: preloadLockedState(),
  isDeleted: preloadDeletedState(),
  userInitialized: false,
  accountSettings: [],
  modules: [],
  appointmentGroups: [],
  workOrderTypes: [],
  paymentTypes: [],
  roles: [],
  wheelTypes: [],
  accountUsers: [],
  accountUsersTable: {
    pageCount: 1,
    orderBy: "username",
    orderDir: "asc",
    filterModels: [],
  },
  carTypes: [],
  workers: [],
  workersTable: {
    pageCount: 1,
    orderBy: "created_at",
    orderDir: "asc",
    filterModels: [],
  },
  organizations: [],
  carNames: [],
  carModels: [],
  products: [],
  productsHashMap: {},
  timesheet: undefined,
  accountInformation: {
    activeFrom: "",
    activeTo: "",
    createdAt: "",
    description: undefined,
    id: -1,
    name: "",
    status: "",
    uuid: "",
    username: "",
    organization: undefined,
    organizations: [],
  },
  activePackage: {
    currency: "EUR",
    description: "",
    duration: "",
    name: "",
    order: -1,
    price: -1,
    uuid: -1,
  },
  smsDefaultMessages: {
    smsMessageAppointmentCreated: {
      ro: "",
      hu: "",
      en: "",
    },
    smsMessageAppointmentDeleted: {
      ro: "",
      hu: "",
      en: "",
    },
    smsMessageAppointmentNotification: {
      ro: "",
      hu: "",
      en: "",
    },
    smsMessageItpNotification: {
      ro: "",
      hu: "",
      en: "",
    },
  },
};

/**
 * Handles creating the a redux state slice
 */
export const accountSlice = createSlice({
  name: ReduxSlice.Account,
  initialState,
  reducers: {
    updateAccountPaymentTypes: (
      state,
      action: PayloadAction<PaymentType[]>,
    ) => {
      state.paymentTypes = action.payload;
    },
    updateAccountWheelTypes: (state, action: PayloadAction<WheelType[]>) => {
      state.wheelTypes = action.payload;
    },
    updateAccountUsers: (state, action: PayloadAction<AccountUser[]>) => {
      state.accountUsers = action.payload;
    },
    updateAccountCarTypes: (state, action: PayloadAction<CarType[]>) => {
      state.carTypes = action.payload;
    },
    updateAccountWorkers: (state, action: PayloadAction<WorkOrderWorker[]>) => {
      state.workers = action.payload;
    },
    updateAccountOrganizations: (
      state,
      action: PayloadAction<UserOrganization[]>,
    ) => {
      state.organizations = action.payload;
    },
    deleteAccountOrganization: (state, action: PayloadAction<number>) => {
      state.organizations = state.organizations.filter(
        (organization) => organization.id !== action.payload,
      );
    },
    updateAccountTimesheet: (
      state,
      action: PayloadAction<Timesheet | undefined>,
    ) => {
      state.timesheet = action.payload;
    },
    _updateUserAccountInformation: (state, action: PayloadAction<User>) => {
      state.accountInformation = action.payload;
    },
    updateUserAccountDeleted: (state, action: PayloadAction<boolean>) => {
      state.isDeleted = action.payload;
    },
    updateAppointmentGroups: (state, action: PayloadAction<Account>) => {
      state.appointmentGroups = getAppointmentGroups(action.payload);
    },
    updateAccountAppointmentGroups: (
      state,
      action: PayloadAction<AppointmentGroup[]>,
    ) => {
      state.appointmentGroups = action.payload;
    },
    updateProductsWithHash: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
      state.productsHashMap = toHashMap(action.payload, "id");
    },
    updateAccountSetting: (state, action: PayloadAction<AccountSetting>) => {
      const { id } = action.payload;
      const settingIndex = state.accountSettings.findIndex(
        (setting) => setting.id === id,
      );

      if (settingIndex !== -1) {
        state.accountSettings[settingIndex] = action.payload;
      } else {
        state.accountSettings.push(action.payload);
      }
    },
    updateLockedState: (state, action: PayloadAction<boolean>) => {
      state.locked = action.payload;
    },
    updateAvatarConfig: (state, action: PayloadAction<AvatarOption>) => {
      state.storedAvatarConfig = action.payload;
    },
    updateAccountUsersTableFilterModels: (
      state,
      action: PayloadAction<FilterModel[]>,
    ) => {
      state.accountUsersTable.filterModels = action.payload;
    },
    resetAccountUsersTablePageCount: (state) => {
      state.accountUsersTable.pageCount = 1;
    },
    updateAccountUsersTablePageCount: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.accountUsersTable.pageCount = action.payload;
    },
    updateAccountUsersTableSort: (
      state,
      action: PayloadAction<{ orderBy: string; orderDir: "asc" | "desc" }>,
    ) => {
      state.accountUsersTable.orderBy = action.payload.orderBy;
      state.accountUsersTable.orderDir = action.payload.orderDir;
    },
    resetAccountUsersTableInitState: (state) => {
      state.accountUsersTable.filterModels = [];
      state.accountUsersTable.orderBy = "username";
      state.accountUsersTable.orderDir = "asc";
      state.accountUsersTable.pageCount = 1;
    },
    resetWorkersTableInitState: (state) => {
      state.workersTable.filterModels = [];
      state.workersTable.orderBy = "created_at";
      state.workersTable.orderDir = "asc";
      state.workersTable.pageCount = 1;
    },
    updateAccountWorkersTableFilterModels: (
      state,
      action: PayloadAction<FilterModel[]>,
    ) => {
      state.workersTable.filterModels = action.payload;
    },
    resetAccountWorkersTablePageCount: (state) => {
      state.workersTable.pageCount = 1;
    },
    updateAccountWorkersTablePageCount: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.workersTable.pageCount = action.payload;
    },
    updateAccountWorkersTableSort: (
      state,
      action: PayloadAction<{ orderBy: string; orderDir: "asc" | "desc" }>,
    ) => {
      state.workersTable.orderBy = action.payload.orderBy;
      state.workersTable.orderDir = action.payload.orderDir;
    },
    updateAccountWorkersLive: (
      state,
      action: PayloadAction<SocketEvent<WorkOrderWorker>>,
    ) => {
      const event = action.payload.event;
      const worker = action.payload.model;

      if (event === "CREATED") {
        state.workers = [...state.workers, worker];
      }

      if (event === "UPDATED") {
        state.workers = state.workers.map((item) => {
          if (item.id === worker.id) return worker;
          return item;
        });
      }

      if (event === "DELETED") {
        state.workers = state.workers.filter((item) => item.id !== worker.id);
      }
    },
    updateAccountUsersLive: (
      state,
      action: PayloadAction<SocketEvent<AccountUser>>,
    ) => {
      const event = action.payload.event;
      const user = action.payload.model;

      if (event === "CREATED") {
        state.accountUsers = [...state.accountUsers, user];
      }

      if (event === "UPDATED") {
        state.accountUsers = state.accountUsers.map((item) => {
          if (item.id === user.id) return user;
          return item;
        });
      }

      if (event === "DELETED") {
        state.accountUsers = state.accountUsers.filter(
          (item) => item.id !== user.id,
        );
      }
    },
    resetAccountState: (state) => {
      state.accountInformation = initialState.accountInformation;
      state.accountUsers = initialState.accountUsers;
      state.accountUsersTable = initialState.accountUsersTable;
      state.workers = initialState.workers;
      state.workersTable = initialState.workersTable;
      state.carTypes = initialState.carTypes;
      state.carModels = initialState.carModels;
      state.carNames = initialState.carNames;
      state.organizations = initialState.organizations;
      state.workOrderTypes = initialState.workOrderTypes;
      state.accountSettings = initialState.accountSettings;
      state.smsDefaultMessages = initialState.smsDefaultMessages;
      state.products = initialState.products;
      state.productsHashMap = initialState.productsHashMap;
      state.timesheet = initialState.timesheet;
      state.activePackage = initialState.activePackage;
      state.appointmentGroups = initialState.appointmentGroups;
      state.isDeleted = false;
      state.userInitialized = false;
      state.paymentTypes = initialState.paymentTypes;
      state.wheelTypes = initialState.wheelTypes;
      state.roles = initialState.roles;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(switchUser.matchFulfilled, (state, { payload }) => {
      state.userInitialized = false;
      state.accountUsersTable = initialState.accountUsersTable;
      state.workersTable = initialState.workersTable;
    });
    builder.addMatcher(
      fetchAccountInformation.matchFulfilled,
      (state, { payload }) => {
        const {
          user,
          account,
          roles,
          modules,
          lists,
          activePackage,
          smsDefaultMessages,
        } = payload.data;
        const {
          users,
          workers,
          carTypes,
          wheelTypes,
          paymentTypes,
          organizations,
          workOrderTypes,
          accountSettings,
        } = account;
        const { carNames } = lists;
        const accountInformation = buildUserAccountData(user, account);
        const carModels = formatCarModels(lists.carModels);

        if (activePackage) {
          state.activePackage = activePackage;
        }

        state.userInitialized = true;
        state.timesheet = accountInformation.lastTimeSheet;
        state.accountSettings = accountSettings;
        state.roles = roles || [];
        state.modules = modules || [];
        state.workers = workers || [];
        state.carNames = carNames || [];
        state.accountUsers = users || [];
        state.wheelTypes = wheelTypes || [];
        state.paymentTypes = paymentTypes || [];
        state.organizations = organizations || [];
        state.workOrderTypes = workOrderTypes || []; // needs local storage
        state.carTypes = uniqBy(carTypes, "name");
        state.carModels = carModels || [];
        state.accountInformation = accountInformation;
        state.appointmentGroups = getAppointmentGroups(account);
        state.isDeleted = accountInformation.status === "deleted";
        state.smsDefaultMessages = smsDefaultMessages;
      },
    );
    builder.addMatcher(searchProducts.matchFulfilled, (state, { payload }) => {
      const { items } = payload.data;

      if (items.length > 0) {
        state.products = items;
        state.productsHashMap = toHashMap(items, "id");
      }
    });
    builder.addMatcher(
      getAccountOrganizations.matchFulfilled,
      (state, { payload }) => {
        const { data } = payload;

        if (data) {
          state.organizations = data;
        }
      },
    );
  },
});

/**
 * Handles updating the account information
 */
export const updateUserAccountInformation =
  (data: Account): AppThunk =>
  (dispatch, getState) => {
    const auth = getState().auth;
    const user = auth.user as unknown as User;

    if (user) {
      const accountInformation = buildUserAccountData(user, data);

      dispatch(
        accountActionCreators._updateUserAccountInformation(accountInformation),
      );
    }
  };

/**
 * Exports the action-creators
 */
export const accountActionCreators = accountSlice.actions;

/**
 * Exports the reducer
 */
export const accountReducer = accountSlice.reducer;
